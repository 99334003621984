import React from "react";
import "./Equipo.css";

import t1 from "../../images/team/t1.png";
import t2 from "../../images/team/t2.png";
import t3 from "../../images/team/t3.png";
import t4 from "../../images/team/t4.png";
import t5 from "../../images/team/t5.png";
import t6 from "../../images/team/t6.png";
import t7 from "../../images/team/t7.png";
import eve from "../../images/team/evelynstudio.png";
import jp from "../../images/team/josepablo.png";
import lea from "../../images/team/leandro.png";

import el from "../../images/el.png";

const Equipo = () => {
  return (
    <div className="ESuper" id="equipo">
      <div className="EContent">
        <div className="ETitle">
          <h1>
            NUESTRO <span>EQU</span>I<span>PO</span>
          </h1>
        </div>
        <div className="ECards">
          <div className="ECardContainer ECC-alt1">
            <div className="ECard">
              <img src={t1} alt="" />
              <p>
                Loreto Castro
                <br />
                Directora Ejecutiva
              </p>
            </div>
          </div>
          <div className="ECardContainer ECC-alt2">
            <div className="ECard">
              <img src={eve} alt="" />
              <p>
                Evelyn Urrutia
                <br />
                Publicista
              </p>
              <div className="Portafolio">
                <a
                  href="https://www.behance.net/MetaphytaEstudio"
                  target="_blank"
                >
                  <img src={el} alt="" />
                  &nbsp;&nbsp;Portafolio
                </a>
              </div>
            </div>
          </div>
          <div className="ECardContainer">
            <div className="ECard">
              <img src={lea} alt="" />
              <p>
                Leandro Zamora
                <br />
                Audiovisual
              </p>
            </div>
          </div>
          <div className="ECardContainer">
            <div className="ECard">
              <img src={jp} alt="" />
              <p>
                Jose Pablo Miranda
                <br />
                Diseñador Gráfico y Director de Arte
              </p>
            </div>
          </div>
          <div className="ECardContainer">
            <div className="ECard">
              <img src={t5} alt="" />
              <p>
                Javiera Corona
                <br />
                Community manager
              </p>
            </div>
          </div>
          <div className="ECardContainer">
            <div className="ECard">
              <img src={t6} alt="" />
              <p>
                Rodrigo Montecinos
                <br />
                Fotógrafo
              </p>
            </div>
          </div>
          <div className="ECardContainer">
            <div className="ECard">
              <img src={t7} alt="" />
              <p>
                Pablo Herrera
                <br />
                Desarrollo Web
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equipo;
