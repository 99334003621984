import React from "react";
import "./Footer.css";

import whatsapp from "../../images/Icons/whatsapp.png";
import mail from "../../images/Icons/mail.png";
import location from "../../images/Icons/location.png";
import instagram from "../../images/Icons/instagram.png";
import tiktok from "../../images/Icons/tiktok.png";
import logo from "../../images/logow.png";

const Footer = () => {
  return (
    <div className="FSuper" id="contacto">
      <div className="FContent">
        <div className="FContactInfo">
          <div className="FTitle">
            <h1>CONTACTO</h1>
          </div>
          <div className="FLinks">
            <div className="FLink">
              <a
                className="FATag"
                href="https://wa.me/56954092399"
                target="_blank"
              >
                <img src={whatsapp} alt="" />
                <h2>+569 5409 2399</h2>
              </a>
            </div>
            <div className="FLink">
              <img src={mail} alt="" />
              <h2>HOLA@SITIOCREACION.CL</h2>
            </div>
            <div className="FLink">
              <img src={location} alt="" />
              <h2>7 NORTE 360 . VIÑA DEL MAR</h2>
            </div>
          </div>
        </div>
        <div className="FContactInfo">
          <div className="FTitle">
            <h1>REDES SOCIALES</h1>
          </div>
          <div className="FLinks">
            <div className="FLink">
              <a
                className="FATag"
                href="https://www.instagram.com/sitiocreacion/"
                target="_blank"
              >
                <img src={instagram} alt="" />
                <h2>@SITIOCREACION</h2>
              </a>
            </div>
            <div className="FLink">
              <a
                className="FATag"
                href="https://www.tiktok.com/@sitio.creacion?_t=8hPxOPFCpcM&_r=1"
                target="_blank"
              >
                <img src={tiktok} alt="" />
                <h2>AGENCIA SITIO CREACION</h2>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="FLogo">
        <img src={logo} alt="" />
      </div>
    </div>
  );
};

export default Footer;
