import React from "react";
import "./Partners.css";

import p1 from "../../images/partner/1.png";
import p2 from "../../images/partner/2.png";
import p3 from "../../images/partner/3.png";
import p4 from "../../images/partner/4.png";
import p5 from "../../images/partner/5.png";
import p6 from "../../images/partner/6.png";
import p7 from "../../images/partner/7.png";
import p8 from "../../images/partner/8.png";

const Partners = () => {
  return (
    <div className="PSuper" id="partner">
      <div className="PContent">
        <div className="PTitle">
          <h1>
            PART<span>N</span>ER
          </h1>
        </div>
        <div className="PLogos">
          <img src={p1} alt="" />
          <img src={p2} alt="" />
          <img src={p3} alt="" />
          <img src={p4} alt="" />
          <img src={p5} alt="" />
        </div>
        <div className="PCollabs">
          <img src={p6} alt="" />
          <img src={p7} alt="" />
          <img src={p8} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
